import * as React from "react";

export function useIsVisible(selector: string, threshold = 0) {
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    const element = document.querySelector(selector);
    if (element == null){
      return;
    }
    let options : IntersectionObserverInit = {
      rootMargin: '0px',
      threshold
    }

    let observer = new IntersectionObserver((entries, observer) => {
      if (entries && entries[0]){
        const entry = entries[0];
        setVisible(entry.isIntersecting);
      }
    }, options);
    observer.observe(element);
  }, [selector])

  return { visible }
}