import * as React from "react";
import Vimeo from '@u-wave/react-vimeo';
import { useInView } from "react-intersection-observer";

import muteIcon from '../assets/images/mute.svg';
import unMuteIcon from '../assets/images/unmute.svg';

import * as styles from "./vimeoPlayer.module.scss";

export const VimeoComponent: React.FC<{
  vimeoId?: string;
  fullWidth?: boolean;
  play?: boolean;
}> = ({ vimeoId, fullWidth, play }) => {
  const { ref, inView, entry } = useInView({});
  const [playing, setPlaying] = React.useState(false);
  const [muted, setMuted] = React.useState(true);
  const playerRef = React.useRef<any>();


  React.useEffect(() => {
    if (playerRef.current) {
      if (muted) {
        playerRef.current.setMuted(true);
      } else {
        playerRef.current.setMuted(false);
      }
    }

  }, [muted])

  React.useEffect(() => {
    if (playerRef.current) {
      if (play) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }

    }

  }, [play])

  return (
    <div
      key={vimeoId}
      ref={ref}
      className={styles.vimeoPlayer}
      data-fullWidth={fullWidth}
    >

      <Vimeo
        video={vimeoId || ''}
        autoplay
        showByline={false}
        showTitle={false}
        background
        muted={muted}
        loop
        onReady={p => playerRef.current = p}
      />
      <button className={styles.muteToggle} onClick={() => setMuted(!muted)}>
        <img src={muted ? unMuteIcon : muteIcon} />
      </button>
    </div>
  );
};
