// extracted by mini-css-extract-plugin
export var bigLogo = "index-module--big-logo--TxhKQ";
export var blackContent = "index-module--black-content--3r0cs";
export var colorWrapper = "index-module--color-wrapper--KFcM4";
export var content = "index-module--content--7WlJI";
export var contentContainer = "index-module--content-container--6Yoq3";
export var fadeIn = "index-module--fade-in--FtF3p";
export var fadeSlideUp = "index-module--fade-slide-up--Xa1hJ";
export var greenArrow = "index-module--green-arrow--eDUzO";
export var heroCopy = "index-module--hero-copy--ECL9K";
export var overlay = "index-module--overlay--pb3Jg";
export var padder = "index-module--padder--k6S3N";
export var shrinkDown = "index-module--shrink-down--gn5XV";
export var video = "index-module--video--KItaY";
export var videoContainer = "index-module--video-container--clrcz";
export var whiteContent = "index-module--white-content--vaKma";