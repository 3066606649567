import { graphql, Link, PageProps } from "gatsby";
import * as React from "react";
import Shell from "../layout/shell";
import { HomePageQuery } from "../generated/graphql";
import { SliceZone } from "@prismicio/react";
import { SliceComponents } from "../slices/slices";
import { ColumnLayout } from "../components/columnLayout";
import { VimeoComponent } from "../components/vimeoPlayer";

import * as styles from "./index.module.scss";
import { ClassNames } from "@rocketmakers/armstrong-edge";
import logo from "../assets/images/logo.svg";
import rightArrow from "../assets/images/icon-rightarrow-green.svg";
import siteShot from "../assets/images/site-shot.jpg";
import { Footer } from "../components/footer";
import { useIsVisible } from "../hooks/useIsVisible";
import { BigHeading } from "../slices/bigHeading";

const Index: React.FC<PageProps<HomePageQuery>> = (pageProps) => {
  const [playAnimation, setPlayAnimation] = React.useState(false);
  const data = pageProps.data.prismicHomePage?.data;
  const newsArticles = pageProps.data.allPrismicNewsArticle.nodes;

  const { visible: padderVisible } = useIsVisible("#padder");
  const { visible: heroVisible } = useIsVisible("#hero-copy", 0.4);
  const { visible: tipVisible } = useIsVisible("#the-tip");
  const { visible: switchableImageVisible } = useIsVisible(
    "#switchable-image",
    0.4
  );
  const { visible: blockListVisible } = useIsVisible("#block-list", 0.6);


  React.useEffect(() => {
    const height = window.innerHeight / 80;
    const onScroll = (e: Event) => setPlayAnimation(window.scrollY > height);
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, []);

  if (!data) {
    return null;
  }
  return (
    <Shell noFooter={true}>
      <div className={styles.colorWrapper}>
        <div id="video" className={styles.videoContainer}>
          <div className={ClassNames.concat(styles.video)}>
            {data.hero_vimeo_id && (
              <VimeoComponent vimeoId={data.hero_vimeo_id} fullWidth={true} play={tipVisible} />
            )}
          </div>
        </div>
        <div id="padder" className={ClassNames.concat(styles.padder)} />
        <div className={styles.contentContainer}>
          <div className={ClassNames.concat(styles.content, styles.blackContent)}>
            <div id="the-tip"/>
            <div
              id="hero-copy"
              className={ClassNames.concat("fixed-width", styles.heroCopy)}
              data-play={playAnimation}
            >
              <img className={styles.bigLogo} src={logo} />
              <h1>Next generation film studios FOR PRODUCERS BY PRODUCERS</h1>
              <p>
                HOP will build on the UK’s reputation as a destination for
                world–class filmmaking. Designed by producers, HOP studios are
                built for efficient production workflows. Large scale
                productions can hit the ground running from day one. On-site
                workshops, equipment and craft services revolve around your
                production and crew’s needs.
              </p>
              <Link to="/studios">
              <img className={styles.greenArrow} src={rightArrow} />
              </Link>
            </div>
          </div>
          <div className={ClassNames.concat(styles.content, styles.whiteContent)}>
            <div className="fixed-width" style={{ paddingTop: "30px" }}>
              <h2>HOP bedfordshire</h2>
            </div>
            <img src={siteShot} />
          </div>
          <SliceZone slices={data.body} components={SliceComponents as any} />
          <div className={styles.whiteContent}>
            {newsArticles && newsArticles.length !== 0 && (
              <div className="fixed-width" style={{ paddingTop: "50px" }}>
                <BigHeading slice={{ primary: { heading: "HOP NEWS" } }}/>
                <ColumnLayout
                  noBorder={true}
                  imageHeight={500}
                  items={newsArticles.map((news) => ({
                    title: news.data.title,
                    description: news.data.summary,
                    image: news.data.preview_image,
                    link: `/news/${news.uid}`,
                  }))}
                />
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </Shell>
  );
};

export const query = graphql`
  query HomePage {
    prismicHomePage {
      data {
        body {
          ... on PrismicHomePageDataBodySwitchableImage {
            id
            slice_type
            items {
              s_image_copy
              s_image_title
              s_image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicHomePageDataBodyFullWidthImage {
            id
            slice_type
            primary {
              full_width_image_image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicHomePageDataBodyCopyBlockOptionalImage {
            id
            slice_type
            primary {
              text_image_body
              text_image_site_page_link
              text_image_title
              left_image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicHomePageDataBodyBlockList {
            id
            slice_type
            primary {
              display_mode
              title
            }
            items {
              list_item_description
              list_item_title
              list_item_image {
                alt
                gatsbyImageData
                url
              }
            }
          }
          ... on PrismicHomePageDataBodyBigHeading {
            slice_type
            primary {
              heading
            }
          }
        }
        title
        hero_image {
          alt
          gatsbyImageData
        }
        hero_vimeo_id
      }
    }
    allPrismicNewsArticle(
      filter: { data: { featured: { eq: true } } }
      limit: 3
    ) {
      nodes {
        uid
        data {
          title
          summary
          preview_image {
            gatsbyImageData
            alt
          }
        }
      }
    }
  }
`;

export default Index;
